@keyframes loading-dots-animation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.loader-root {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader-dots {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  transform: translate(-50%, -50%);
}

.loader-dot {
  border-radius: 50%;
  background-color: #444f67;
  animation: loading-dots-animation 1.8s infinite ease-out;
}

.loader-dot-md {
  margin-right: 7px;
  width: 10px;
  height: 10px;
}

.loader-dot-sm {
  width: 6px;
  height: 6px;
  margin-right: 4px;
}

.loader-dots .loader-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loader-dots .loader-dot:nth-child(2) {
  animation-delay: -0.16s;
}
