.autocomplete-textfield {
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  height: 48px;
  padding: 12px, 16px, 12px, 16px;
  background-color: #fff;
}

.autocomplete-textfield {
  border: 1px solid #c0c0c0;

}
.autocomplete-input  {
  border: none;
  background-color: transparent;
  outline: none ;
  width: 100%;
  
}

.input-close  {
    border:none
}

.input-close::placeholder {
 color: black;
 font-weight: 500;
}

.autocomplete-input::placeholder {
  color: #747474;
}
