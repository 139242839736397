.App {
  text-align: center;
  display: flex;
  height: 100%;
  background-color: blue;

}

.sisense-embed {
  width: 100%;
  height: 200vh;
}