@keyframes wize-logo {
  from {
  }
  to {
    transform: scale(0.7);
  }
}

.wize-logo-animated {
  animation: wize-logo 1.2s infinite alternate ease-in-out;
}
